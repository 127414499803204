<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="cash-goods">
      <div class="cash-goods-in">
        <b-container class="cash-goods-cont" fluid>
          <div class="style">
            <div :class="style_id==''?'style-all-selected':'style-all'" @click="chooseStyle('')">
              <span class="style-txt">全部风格</span>
              <span class="style-eng">All style</span>
            </div>
            <div class="style-item-cont">
              <div
                :class="style_id == item.label_id?'style-item-selected':'style-item'"
                v-for="(item, index) in styleList"
                :key="index"
                @click="chooseStyle(item.label_id)"
              >
                <span class="style-txt">{{ item.label_name }}</span>
                <span class="style-eng">{{ item.english_name }}</span>
              </div>
            </div>
          </div>
          <b-row class="brand-title-img">
            <b-col class="brand-img-in" sm="6" md="4" lg="4" xl="3">
              <img
                src="@/../public/icon/mall/cashGoods/mainbrand.png"
                alt=""
              />
            </b-col>
          </b-row>
          <b-row class="brand-cont">
            <b-col
              class="brand-item"
              v-for="(item, index) in mainProducts"
              :key="index"
              sm="6"
              md="4"
              lg="4"
              xl="4"
            >
              <div class="brand-in" @click="toProductDetail(item)">
                <img class="brand-img" :src="item.GOODS_IMG" :alt="item.GOODS_NAME" />
                <div class="brand-detail">
                  <div class="name">{{item.GOODS_NAME}}</div>
                  <span>{{ item.SALE_PRICE }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import Right from "@/components/Right.vue";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      Right,
      footerNav
    },
    data() {
      return {
        styleList: [], //风格列表
        mainProducts: [], // 主推商品
        style_id: "", //风格id
        cityId: "", //城市id
      };
    },
    created() {
      this.cityId = localStorage.getItem("city");
      this.getMainProducts();
      this.getGoodsStyles();
    },
    methods: {
      // 获取产品风格
      getGoodsStyles(){
        this.$api.goodsStyles({
          type: 14
        }).then(res => {
          if(res.status == 100){
            this.styleList = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 获取主推商品
      getMainProducts(){
        this.$api.mainProducts({
          style_type: this.style_id,
          city_id: this.cityId
        }).then(res => {
          if(res.status == 100){
            this.mainProducts = res.data.goods;
          }
        }).catch(err => console.error(err))
      },
      
      //选择风格
      chooseStyle(id){
          this.style_id = id;
          this.getMainProducts();
      },
      toProductDetail(obj){
        dump.link({
          type: 3,
          link: "/product/details",
          params: {
            good_id: obj.GOODS_ID
          }
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/view/mall/cashGoods/cashGoods";
</style>
